import './index.css'
import $ from 'jquery'
import { Fragment } from 'react';
import Base from "../base";

export default class Maper extends Base
{
    constructor(props) {
        super(props);
        this.state = {
            position:[],
            markerPosition :{},
        }
    }

    componentWillMount() {
        this.requireCss([
            "https://cache.amap.com/lbs/static/main1119.css"
        ])
        this.requireJs([
            "https://webapi.amap.com/maps?v=1.4.15&key=e5beccb6d67a5a72cc1eed8cab5ed26e&plugin=AMap.Autocomplete,AMap.PlaceSearch,AMap.Geocoder",
            "https://a.amap.com/jsapi_demos/static/demo-center/js/demoutils.js"
        ])
        $(document).ready(() => {
            setTimeout(() => {
                this.requireJs([
                    "https://cache.amap.com/lbs/static/addToolbar.js"
                ])
            }, 1000)
        })
    }

    componentDidMount() {
        var url = this.props.location.search
        var city;
        if (url) {
            var arr = url.split("&");
            var position = [];
            var markerPosition = {};
            for(var k in arr) {
                var pa = arr[k].split('=');
                if (arr[k].indexOf('lon') >= 0 && pa[1] && pa[1] !== 'null' && pa[1] !== 'undefined') {
                    position[0] = pa[1];
                    markerPosition.longitude = pa[1];
                }
                if (arr[k].indexOf('lat') >= 0 && pa[1] && pa[1] !== 'null' && pa[1] !== 'undefined') {
                    position[1] = pa[1];
                    markerPosition.latitude = pa[1];
                }
                if (!position.length && arr[k].indexOf('city') >= 0 && pa[1] && pa[1] !== 'null' && pa[1] !== 'undefined') {
                    city = decodeURI(pa[1]);
                }
            }

            this.setState({
                position:position,
                markerPosition:markerPosition
            })
        }
        window.onload = () => {
            /* global layui */
            layui.use('form');

            var conf = {
                resizeEnable:true,
                zoom:11
            };
            if (this.state.position.length) {
                conf.center = this.state.position;
            }

            var map = new window.AMap.Map("container", conf);
            if (city) {
                map.setCity(city);
            }
            //输入提示
            var autoOptions = {
                input: "tipinput"
            };
            var auto = new window.AMap.Autocomplete(autoOptions);
            var placeSearch = new window.AMap.PlaceSearch({
                map: map
            });
            //构造地点查询类
            window.AMap.event.addListener(auto, "select", select);//注册监听，当选中某条记录时会触发
            function select(e) {
                placeSearch.setCity(e.poi.adcode);
                placeSearch.search(e.poi.name);  //关键字查询查询
            }

            window.AMap.plugin('AMap.ToolBar', () => {
                var toolbar = new window.AMap.ToolBar();
                map.addControl(toolbar);
            })

            var pixel = new window.AMap.Pixel(-13, -30);
            var marker;

            map.on('complete', () => {
                var center = map.getCenter();
                showMarker(center.lng, center.lat);
                markerDrag();
            })

            // 清除 marker
            function clearMarker() {
                if (marker) {
                    marker.setMap(null);
                    marker = null;
                }
            }

            // 实例化点标记
            function showMarker(lng, lat) {
                clearMarker();
                //标记
                marker = new window.AMap.Marker({
                    icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
                    position:[lng, lat],
                    animation:'AMAP_ANIMATION_DROP',
                    raiseOnDrag:true,
                    offset: pixel,
                    draggable:true,
                    cursor:'move',
                });

                marker.setMap(map);
                getLocationInfo(lng, lat);
            }

            function showInfoM(e) {
                getLocationInfo(e.lnglat.lng, e.lnglat.lat);
            }

            function markerDrag() {
                if (marker) {
                    marker.on('dragging', showInfoM)
                }
            }

            var clickHandler = function(e) {
                showMarker(e.lnglat.lng, e.lnglat.lat)
            };

            map.on('click', clickHandler);

            window.AMap.event.addListener(placeSearch, 'markerClick', function(e) {
                var data = e.data;
                getLocationInfo(data.location.lng, data.location.lat);
            });

            function getLocationInfo(lng, lat) {
                window.AMap.service('AMap.Geocoder',function(){
                    $('input[name=lat]').val(lat);
                    $('input[name=lng]').val(lng);
                    var geocoder = new window.AMap.Geocoder({
                        city: ""//城市，默认：“全国”
                    });

                    var lnglatXY = [lng, lat];
                    var address;
                    geocoder.getAddress(lnglatXY, function(status, result) {
                        if (status === 'complete'&& result.regeocode) {
                            address = result.regeocode.formattedAddress;
                            var country =  result.regeocode.addressComponent.country;
                            var province =  result.regeocode.addressComponent.province;
                            var city =  result.regeocode.addressComponent.city;
                            var district =  result.regeocode.addressComponent.district;
                            var street =  result.regeocode.addressComponent.street;

                            $('input[name=province]').val(province);
                            $('input[name=city]').val(city);
                            $('input[name=area]').val(district);
                            $('input[name=address]').val(address);
                        }
                    });
                })
            }
        }
    }

    render() {
        return (
            <Fragment>
                <div id="container"></div>
                <div id="myPageTop">
                    <table>
                        <thead>
                        <tr>
                            <td>
                                <label>请输入关键字：</label>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <input id="tipinput"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <form className="layui-form" id="myForm">
                    <input type="hidden" name="province" />
                    <input type="hidden" name="city" />
                    <input type="hidden" name="area" />
                    <input type="hidden" name="address" />
                    <input type="hidden" name="lat" />
                    <input type="hidden" name="lng" />
                    <div className="layui-form-item layui-hide">
                        <input type="button" lay-submit={""} lay-filter="LAY-user-front-submit"
                               id="LAY-user-front-submit" value="确认" />
                    </div>
                </form>
            </Fragment>

        );
    }
}

